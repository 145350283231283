body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("//z-delivery.miam-nixulabs.com/assets/z-delivery-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  background-image: url("//z-delivery.miam-nixulabs.com/assets/z-insurance-logo-nobg.png");
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile {
  border-radius: 50%;
}

.app-headline {
  color: #999999;
  font-size: 1.5em;
}

.z-delivery-screen {
  position: absolute;
  top: 28%;
  left: 43%;
  height: 45%;
  width: 14.3%;
  overflow: auto;
  background-color: #111111
}

.z-package-overlay {
  position: absolute;
  top: 79%;
  left: 43%;
  height: 16%;
  width: 14.3%;
  overflow: auto;
  background-color: #111111;
  transition: height 2s;
}

.delivered {
  height: 0%;
}

.code {
  margin-bottom: 1em;
  padding: 0;
}

.qr-code {
  display: inline-block;
  border-radius: 1em;
  margin-top: 1em;
}